@import '../../../../../styles/variables';

// ------------------------------
// Layout
// ------------------------------
$gutter-base: 15px;
$gutter-m: 30px;

// ------------------------------
// Colors
// ------------------------------
// Grays
$gray-200: #333;
$gray-400: #999;
