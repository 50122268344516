.#{$pdp-prefix}-empty-state {
  position: relative;
  min-height: 25em;

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__icon,
  &__link {
    display: block;
    width: 100%;
    text-align: center;
  }

  &__title {
    color: $gray-200;
    font-size: $font-size-20;
    text-align: center;
    margin-top: 1.645em;
    padding: 0 $gutter-base;
    line-height: $line-height-xs;
  }

  &__description {
    color: $gray-400;
    font-size: $font-size-14;
    text-align: center;
    margin-top: 0.571em;
    padding: 0 $gutter-m;
    line-height: $line-height-xs;
  }

  &__link {
    margin-top: 1.1429em;
    line-height: $line-height-xs;
  }

  &__link-container {
    display: block;
    text-align: center;
    font-size: $font-size-base;
    margin-top: 2em;
    padding: 0 $gutter-m;
  }
}
