@import '../../../styles/functions';
@import '../../../styles/variables';
@import './questions';
@import './questions.stock-modal';

@import '../components/questions-ai';

.#{$pdp-prefix}-questions {
  margin-bottom: $andes-spacing-48;
  width: 100%;

  &__list {
    display: inline-block;

    &-container {
      display: inline-block;
      position: relative;
    }
  }

  &__tooltip {
    display: inline-block;
    position: absolute;
    top: -14px;
    right: -215px;
    margin-left: 6px;
    background-color: $andes-blue-500;
    color: $andes-white;
    padding: 16px;
    border-radius: 4px;
    z-index: 2;
    box-shadow: 0 3px 7px 0 rgba($andes-black, 0.15), 0 0 2px 0 rgba($andes-black, 0.2);
    width: 212px;
    height: auto;

    &__primary {
      font-weight: 600;
      margin-bottom: 5px;
      font-size: 14px;
    }

    p {
      line-height: 14px;
    }

    &__close {
      width: 12px;
      height: 12px;
      cursor: pointer;
      position: absolute;
      display: block;
      right: 16px;
      top: 17px;
    }

    &__close::after {
      transform: rotate(-45deg);
    }

    &__close::before {
      transform: rotate(45deg);
    }

    &__close::after,
    &__close::before {
      background-color: color(color_white);
      position: absolute;
      left: 6px;
      top: 0;
      content: '';
      height: 12px;
      width: 1px;
      cursor: pointer;
    }
  }

  &__tooltip::after {
    content: '';
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid $andes-blue-500;
    position: absolute;
    top: 33px;
    left: -7px;
  }

  &__form {
    display: flex;
    margin-bottom: 24px;
  }

  &__title {
    font-size: $font-size-24;
    font-weight: $font-weight-regular;
    margin-bottom: 40px;
  }

  &__title,
  &__subtitle,
  &__subtitle--question {
    color: color(color_gray_dark);
  }

  &__subtitle {
    font-size: $font-size-18;

    // se define este valor 4px por que los __item tienen margin-top de 12px
    // para lograr una separación total de 16px
    padding-bottom: 4px;
    font-weight: 600;
  }

  &__subtitle--question {
    font-size: $font-size-18;
    margin-bottom: 14px;
    margin-top: 44px;
    font-weight: 600;
  }

  &__content-input {
    display: inline-block;
  }

  &__input {
    width: 100%;
    font-size: $font-size-15;
    padding-top: 0;
    height: fit-content !important;

    .andes-form-control__control {
      .andes-form-control__field {
        text-indent: 0;
        padding-left: $andes-spacing-16;
        padding-right: $andes-spacing-16;
        height: fit-content !important;
        min-height: 48px !important;
      }

      .andes-form-control__field:hover,
      .andes-form-control__field:focus {
        border-width: 1px;
      }
    }

    & .andes-form-control__field::placeholder {
      font-size: $font-size-15;
    }
  }

  .#{$pdp-prefix}-container__row--questions div:not(&__checkbox-credits-interest__wrapper) + &__button {
    margin-left: 16px;
  }

  div:not(&__checkbox-credits-interest__wrapper) + &__button {
    display: inline-block;
    font-size: $font-size-16;
    height: 48px;
    padding: 16px 0;
    vertical-align: top;
    width: 184px;
  }

  &__questions-list {
    &__answer-item__denounce,
    &__question-item__denounce {
      opacity: 0;
      padding: 2px 0 0;
    }

    &__item__denounce--VISIBLE {
      opacity: 1;
    }

    &__title {
      font-size: 18px;
    }

    &__item-questions--others-questions:first-child {
      padding-top: 8px;
    }

    &__question-wrap {
      display: flex;
      flex-wrap: wrap;
    }

    &__question-item__denounce__overriding-default-button,
    &__question-item__denounce__overriding-default-button:hover,
    &__question-item__denounce__overriding-default-button:active,
    &__question-item__denounce__overriding-default-button:focus,
    &__answer-item__denounce__overriding-default-button,
    &__answer-item__denounce__overriding-default-button:hover,
    &__answer-item__denounce__overriding-default-button:focus,
    &__answer-item__denounce__overriding-default-button:active {
      border-color: transparent;
      background-color: transparent;
      outline: none;
      box-shadow: none;
      color: color(color_blue);
      cursor: pointer;
      font-weight: 400;
      /* stylelint-disable-next-line prettier/prettier */
      height: 22px !important;
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__question-item__denounce__overriding-default-button:active,
    &__answer-item__denounce__overriding-default-button:active {
      color: color(color_blue_700);
    }
  }

  &__content {
    display: flex;
    width: 100%;

    .#{$pdp-prefix}-questions__button {
      margin-left: $andes-spacing-16;
    }
  }

  &__checkbox-credits-interest-content {
    display: inline-block;
    width: 100%;
  }

  &__checkbox-credits-interest__wrapper &__button {
    width: auto;
  }

  &__time-text {
    display: block;
    margin-top: 8px;
    font-size: $font-size-14;
    color: color(color_gray_middle);
  }

  .#{$pdp-prefix}-action-modal {
    margin-top: 0;
  }

  &__item {
    display: inline-block;
    margin-right: 12px;

    // Se define en este punto el margen superior, para que cuando los items no entren
    // todos en la misma linea queden bin separados, 12px de margen superior y derecho
    margin-top: 12px;

    &:last-child {
      margin-right: 15px;
    }
  }

  .andes-form-control__field {
    margin-top: 0;
  }

  .andes-form-control__message-fixed {
    margin-top: 8px;
  }

  &__message-container {
    margin-top: 24px;
    padding: 9px 16px;
    border-radius: 4px;
    display: inline-block;
  }

  &__stock-modal {
    width: 500px;
  }

  &__message-location {
    &--simple {
      .#{$pdp-prefix}-icon,
      svg {
        width: 20px;
        height: 20px;
      }

      .#{$pdp-prefix}-media__body {
        margin-bottom: $andes-spacing-24;
      }
    }

    &--container {
      margin-bottom: $andes-spacing-16;
      width: fit-content;

      .andes-message__content {
        width: fit-content;
        padding-right: $andes-spacing-32;
      }
    }
  }

  &__no-questions--text {
    span {
      font-weight: $font-weight-regular;
    }
  }

  &__questions-others-question-modal {
    .#{$pdp-prefix}-action-modal__link {
      font-size: $font-size-14;
    }
  }

  &__checkbox-credits-interest__wrapper {
    display: flex;
    margin-bottom: $andes-spacing-24;

    .andes-checkbox__label {
      line-height: $line-height-s;
      display: inline-block;
    }
  }
}

.#{$pdp-prefix}-container__row--questions-short-description {
  .#{$pdp-prefix}-questions {
    &__form {
      padding-top: $andes-spacing-32;
      display: block;
      margin-bottom: unset;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__content-input {
      display: block;
    }

    &__button {
      display: block;
      width: 100%;
      margin: 0;
    }

    .ui-pdp-questions__input .andes-form-control__control .andes-form-control__field {
      min-height: 112px !important;
    }
  }
}

.ui-pdp-action-modal__modal.ui-pdp-action-modal__buy-multiple-variations {
  width: 672px;
  height: 532px;

  .andes-modal__scroll {
    overflow-y: hidden;
  }

  .andes-modal__scroll > .andes-modal__header {
    padding: $andes-spacing-48 $andes-spacing-48 $andes-spacing-32;
    margin: 0;
  }
}
