$variations-picker-gap: 8px;

.#{$pdp-prefix}-variations {
  width: 100%;
  margin-top: 25px;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;

  .#{$pdp-prefix}-thumbnail {
    &--SELECTED {
      .#{$pdp-prefix}-thumbnail__picture {
        width: 42px;
        height: 42px;
      }
    }

    &__container {
      display: inline-flex;
      flex-direction: column;
      padding: $andes-spacing-8 $andes-spacing-12;
      align-items: center;
      justify-content: center;
      max-width: 250px;
    }

    &__label {
      color: color(color_black_100);
      padding: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 80px;
    }

    &__subtitle {
      font-size: 10px;
      padding: 0;
    }

    &__price {
      .ui-pdp-price__part {
        line-height: $line-height-s;
      }
    }
  }

  &__title {
    width: 100%;
  }

  &__picker {
    display: flex;
    flex-wrap: wrap;

    // TODO: please validate with UX team the space between validations
    // gap: $variations-picker-gap;

    &-default-container {
      display: flex;
      flex-wrap: wrap;

      .#{$pdp-prefix}-price {
        &__second-line {
          transform: unset;
        }
      }
    }

    &-default-container-error {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: -5.5px;
        top: -5.5px;
        border-style: solid;
        border-width: 1.5px;
        border-color: $andes-red-500;
        border-radius: 6px;
        box-sizing: content-box;
      }
    }

    .ui-pdp-variations__title {
      margin-bottom: $variations-picker-gap;
    }

    .ui-pdp-variations--thumbnail {
      margin: 0 $variations-picker-gap $variations-picker-gap 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .andes-list__item--size-medium .andes-list__item-first-column {
      padding: 0;
    }

    & > .andes-dropdown.andes-dropdown--form {
      &.ui-pdp-dropdown-selector.andes-form-control {
        width: 100%;
      }

      .andes-dropdown__trigger span {
        display: flex;
        align-items: center;
        flex-basis: content;
      }
    }

    .andes-floating-menu--show .andes-card__content .andes-list--default.andes-list--selectable.andes-floating-menu {
      overflow-x: hidden;
    }
  }

  &__picker-single + .#{$pdp-prefix}-variations__picker {
    margin-top: $andes-spacing-12;
  }

  &__picker-single + .#{$pdp-prefix}-variations__picker-single {
    margin-top: 0;
  }

  &__picker-size-specs-chart + .#{$pdp-prefix}-variations__picker {
    margin-top: $andes-spacing-20;
  }

  &__picker-size-spec-sizechart-fit {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    flex-wrap: wrap;
  }

  &__tooltip {
    display: inline-block;
  }

  &__label {
    margin-top: $andes-spacing-4;
    font-weight: $font-weight-regular;
    font-size: $font-size-16;
    max-width: 340px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.5s ease-out 0s;

    &-only-text {
      margin-bottom: $andes-spacing-8;
      margin-top: $andes-spacing-4;
    }

    &-error {
      display: inline-block;
      margin-left: $andes-spacing-8;
    }
  }

  &__label--with-subtitle {
    margin-bottom: 0;
  }

  &__selected-label {
    margin-left: 4px;
    font-weight: $font-weight-semibold;
    text-overflow: ellipsis;
    transition: all 0.5s ease-out 0s;

    .#{$pdp-prefix}-icon {
      position: relative;
      width: 16px;
      height: 16px;
      top: 3px;
      transition: all 0.5s ease-out 0s;

      &--message-warning {
        margin-right: $andes-spacing-4;
      }
    }
  }

  &__selected-text {
    display: inline;
    margin-right: $andes-spacing-4;

    .#{$pdp-prefix}-icon {
      margin-right: $andes-spacing-4;
    }
  }

  &__selected-title {
    font-weight: $font-weight-semibold;
  }

  &__selected-subtitle {
    margin-left: $andes-spacing-4;

    &::before {
      content: '|';
      margin-right: $andes-spacing-4;
    }
  }

  &__subtitle {
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
  }

  &--thumbnail {
    margin: 0;

    // @todo, post rollout buscar alguna mejor solución
    // pasamos de 48 a 44px para que puedan entrar 6 items
    .#{$pdp-prefix}-thumbnail__picture {
      width: 44px;
      height: 44px;
    }
  }

  &__expand-action {
    display: inline-block;
    font-weight: $font-weight-regular;
    cursor: pointer;

    .ui-pdp-icon--chevron-down {
      vertical-align: middle;
      margin-left: 7px;
      width: 10px;
      height: 10px;
    }
  }

  &__alert {
    padding: 2px 0 8px;

    &-text {
      margin-left: 5px;
    }
  }

  // Styles for refurbished products
  &--refurbished {
    .#{$pdp-prefix}-thumbnail__container,
    .#{$pdp-prefix}-thumbnail--BLOCKED,
    .#{$pdp-prefix}-thumbnail--DISABLED {
      align-items: flex-start;
    }

    .#{$pdp-prefix}-thumbnail__label {
      font-size: $font-size-14;
    }
  }
}
